export const focus = {
  mounted(el) {
    const input = el.firstChild;
    el.addEventListener('focus', (evt) => {
      evt.preventDefault();

      if (input.disabled) {
        return;
      } else {
        el.classList.add('active', 'filled');

        input.focus();
        if (el)
          input.onblur = () => {
            if (!input.value || input.value === '+7') {
              el.classList.remove('active', 'filled');
            } else {
              el.classList.remove('active');
            }
          };
      }
    });
  },
};

export const validation = {
  mounted(el, options) {
    const currentLanguage = options.value.language;
    const validationMessageList = options.value.validationMessageList;
    const validateForm = el;
    const inputList = Array.from(validateForm.querySelectorAll('.form__label-input'));
    const submitButton = validateForm.querySelector('.form__submit-button');
    const labelButton = validateForm.querySelector('.form__label-button');
    const labelTextSelector = '.form__label-text';
    const inactiveButtonClass = 'form__submit_disabled_button';
    const labelErrorClass = 'label-error';

    let numberValidity = {
      valid: false,
    };

    let cardNumberValidity = {
      valid: false,
    };

    let cardDateValidity = {
      valid: false,
    };

    function setEventListeners() {
      validateForm.reset();
      const changeEventId = ['cardNumber', 'cardDate', 'cardCvv'];
      const keyupEventId = ['telNumber', 'email', 'sixDigitsCode'];

      deactiveButton();
      if (labelButton) {
        deactiveLabelButton();
      }

      inputList.forEach((inputElement) => {
        if (changeEventId.includes(inputElement.id)) {
          inputElement.addEventListener('change', (evt) => {
            const inputLabel = evt.target.closest('label');
            validationDistribute(inputElement, inputLabel);
            deactiveButton();
            if (labelButton) {
              deactiveLabelButton();
            }
          });
        } else if (keyupEventId.includes(inputElement.id)) {
          inputElement.addEventListener('keyup', (evt) => {
            const inputLabel = evt.target.closest('label');
            validationDistribute(inputElement, inputLabel);
            deactiveButton();
            if (labelButton) {
              deactiveLabelButton();
            }
          });
        }
      });
    }

    function validationDistribute(inputElement, inputLabel) {
      const inputId = inputElement.id;

      if (inputId === 'cardNumber') {
        checkCardNumberValidity(inputElement, inputLabel);
      } else if (inputId === 'cardDate') {
        checkCardDateValidity(inputElement, inputLabel);
      } else if (inputId === 'telNumber') {
        checkNumberValidity(inputElement, inputLabel);
      } else {
        checkInputValidity(inputElement, inputLabel);
      }
    }

    //? Валидация номера банковской карты
    function checkCardNumberValidity(inputElement, inputLabel) {
      const cardNo = inputElement.value.replace(/[^\d]/g, '');
      let nDigits = cardNo.length;
      let nSum = 0;
      let isSecond = false;
      for (let i = nDigits - 1; i >= 0; i--) {
        let d = cardNo[i].charCodeAt() - '0'.charCodeAt();

        if (isSecond == true) d = d * 2;
        nSum += parseInt(d / 10, 10);
        nSum += d % 10;

        isSecond = !isSecond;
      }

      const validity = () => {
        let validationMessage;
        if (inputElement.value.length === 0) {
          validationMessage = validationMessageList.missingField;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else if (inputElement.value.length < inputElement.getAttribute('minlength')) {
          validationMessage =
            validationMessageList.cardNumberLength +
            inputElement.value.replace(/[^\d]/g, '').length;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else if (
          inputElement.value.length == inputElement.getAttribute('minlength') &&
          nSum % 10 !== 0
        ) {
          const validationMessage = validationMessageList.cardWrongNumber;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else {
          hideError(inputElement, inputLabel);
          return true;
        }
      };
      cardNumberValidity.valid = validity();
    }

    //? Валидация даты банковской картыё
    function checkCardDateValidity(inputElement, inputLabel) {
      const date = new Date();
      const yearNow = date.getFullYear() % 100;
      const inputMonth = inputElement.value.slice(0, 2);
      const inputDate = inputElement.value.slice(3, 5);

      const validity = () => {
        let validationMessage;
        if (inputElement.value.length === 0) {
          validationMessage = validationMessageList.missingField;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else if (inputDate < yearNow) {
          validationMessage = validationMessageList.cardExpirationDate;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else if (inputMonth > 12) {
          validationMessage = validationMessageList.incorrectData;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else {
          hideError(inputElement, inputLabel);
          return true;
        }
      };
      cardDateValidity.valid = validity();
    }

    //? Валидация номера телефона
    function checkNumberValidity(inputElement, inputLabel) {
      const allowedOperators = [
        '700',
        '701',
        '702',
        '705',
        '706',
        '707',
        '708',
        '747',
        '771',
        '775',
        '776',
        '777',
        '778',
      ];

      const validity = () => {
        let validationMessage;
        if (inputElement.value.length <= 6) {
          return false;
        } else if (
          !allowedOperators.includes(inputElement.value.slice(4, 7)) &&
          inputElement.value.length >= 7
        ) {
          validationMessage = validationMessageList.telWrongOperator;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else if (
          allowedOperators.includes(inputElement.value.slice(4, 7)) &&
          inputElement.value.length < inputElement.getAttribute('minlength')
        ) {
          validationMessage =
            validationMessageList.telNumberLength + inputElement.value.replace(/[^\d]/g, '').length;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else {
          hideError(inputElement, inputLabel);
          return true;
        }
      };
      numberValidity.valid = validity();
    }

    //? Валидация полей ввода

    function checkInputValidity(inputElement, inputLabel) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;

      let validationMessage;

      if (inputElement.value.length === 0) {
        validationMessage = validationMessageList.missingField;
        showError(inputElement, inputLabel, validationMessage);
      } else if (inputElement.type === 'email') {
        if (!emailRegex.test(inputElement.value)) {
          validationMessage = validationMessageList.incorrectEmail;
          showError(inputElement, inputLabel, validationMessage);
          return false;
        } else {
          hideError(inputElement, inputLabel);
        }
      } else if (inputElement.value.length < inputElement.getAttribute('minlength')) {
        if (currentLanguage === 'ru') {
          validationMessage = `Минимальное количество символов: ${inputElement.getAttribute(
            'minlength',
          )} сейчас введено: ${inputElement.value.length}`;
          showError(inputElement, inputLabel, validationMessage);
        } else if (currentLanguage === 'en') {
          validationMessage = `Minimum number of characters: ${inputElement.getAttribute(
            'minlength',
          )} now: ${inputElement.value.length}`;
          showError(inputElement, inputLabel, validationMessage);
        }
      } else {
        hideError(inputElement, inputLabel);
      }
    }

    function showError(
      inputElement,
      inputLabel,
      validationMessage = inputElement.validationMessage,
    ) {
      inputLabel.classList.add(labelErrorClass);
      const errorElement = inputLabel.querySelector(labelTextSelector);
      errorElement.textContent = validationMessage;
    }

    function hideError(inputElement, inputLabel) {
      inputLabel.classList.remove(labelErrorClass);
      const errorElement = inputLabel.querySelector(labelTextSelector);
      errorElement.textContent = '';
    }

    function hasInvalidInput() {
      return inputList.some((inputElement) => {
        if (inputElement.id === 'telNumber') {
          return !numberValidity.valid;
        } else if (inputElement.getAttribute('disabled') !== null) {
          return true;
        } else if (inputElement.id === 'cardNumber') {
          return !cardNumberValidity.valid;
        } else if (inputElement.id === 'cardDate') {
          return !cardDateValidity.valid;
        } else {
          return !inputElement.validity.valid;
        }
      });
    }

    function deactiveButton() {
      if (hasInvalidInput()) {
        submitButton.classList.add(inactiveButtonClass);
        submitButton.setAttribute('disabled', true);
      } else {
        submitButton.classList.remove(inactiveButtonClass);
        submitButton.removeAttribute('disabled');
      }
    }

    function deactiveLabelButton() {
      if (!numberValidity.valid) {
        // labelButton.classList.add(inactiveButtonClass)
        // labelButton.setAttribute('disabled', true)
      } else {
        // labelButton.classList.remove(inactiveButtonClass)
        // labelButton.removeAttribute('disabled')
      }
    }

    setEventListeners();
  },
};
