<template>
    <div class="max-w-[900px] text-[9px] w-full flex justify-center gap-1 mt-auto py-2 mq800:text-[12px]">
        <h2 class="font-semibold opacity-75">{{ this.$store.state.widgetTextData.offerText }}</h2>
        <a target="_blank" href="https://docs.paygate.kz/agreement/offer.pdf" class="font-bold text-blue-500 visited:text-[#59499a]"
            >{{ this.$store.state.widgetTextData.offerLinkText }}
        </a>
    </div>
</template>

<script>
export default {}
</script>
