import { createRouter, createWebHistory } from 'vue-router'

//? Импорт компонентов
import CardForm from '@/components/PaymentForms/CardForm'
import DcbForm from '@/components/PaymentForms/DcbForm'
import EwalletForm from '@/components/PaymentForms/EwalletForm'
import App from '@/App'

const routes = [
    {
        path: '/:id',
        component: DcbForm,
    },
]

const router = createRouter({
    routes,
    history: createWebHistory(),
})

export default router
