<template>
    <div class="max-w-[450px] w-full bg-white rounded-[10px] shadow-2xl px-5 py-[50px]">
        <h1 class="font-bold text-4xl mb-8">Payment by Card</h1>
        <form class="flex flex-col gap-5">
            <label class="flex flex-col p-2.5 border border-solid border-[#DEE7EF] bg-[#EFF6F7] rounded-[10px]">
                <p class="font-semibold text-[16px] text-[#94A6AD]">Card number</p>
                <input
                    class="text-[17px] font-semibold outline-none bg-transparent"
                    placeholder="#### #### #### ####"
                    type="text"
                    v-mask="'#### #### #### ####'" />
            </label>
            <div class="flex gap-4">
                <label
                    class="max-w-[150px] w-full flex flex-col p-2.5 border border-solid border-[#DEE7EF] bg-[#EFF6F7] rounded-[10px]">
                    <p class="font-semibold text-[16px] text-[#94A6AD]">Month</p>
                    <select
                        class="text-[17px] font-semibold outline-none bg-transparent"
                        id="cardMonth"
                        data-ref="cardDate">
                        <option value="" disabled selected>00</option>
                        <option
                            v-bind:value="n < 10 ? '0' + n : n"
                            v-for="n in 12"
                            v-bind:disabled="n < minCardMonth"
                            v-bind:key="n">
                            {{ n < 10 ? '0' + n : n }}
                        </option>
                    </select>
                    <!-- <select
                        class="card-input__input -select"
                        id="cardYear"
                        v-model="cardYear"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate">
                        <option value="" disabled selected>Year</option>
                        <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                            {{ $index + minCardYear }}
                        </option>
                    </select> -->
                </label>
                <label class="flex flex-col p-2.5 border border-solid border-[#DEE7EF] bg-[#EFF6F7] rounded-[10px]">
                    <p class="font-semibold text-[16px] text-[#94A6AD]">MM/YY</p>
                    <select
                        class="text-[17px] font-semibold outline-none bg-transparent"
                        id="cardMonth"
                        v-model="cardMonth"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate">
                        <option value="" disabled selected>Month</option>
                        <option
                            v-bind:value="n < 10 ? '0' + n : n"
                            v-for="n in 12"
                            v-bind:disabled="n < minCardMonth"
                            v-bind:key="n">
                            {{ n < 10 ? '0' + n : n }}
                        </option>
                    </select>
                    <!-- <select
                        class="card-input__input -select"
                        id="cardYear"
                        v-model="cardYear"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate">
                        <option value="" disabled selected>Year</option>
                        <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                            {{ $index + minCardYear }}
                        </option>
                    </select> -->
                </label>
            </div>
        </form>
    </div>
</template>
<script>
export default {}
</script>
<style></style>
