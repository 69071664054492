<template>
  <div
    class="max-w-[450px] w-full bg-white rounded-[10px] shadow-2xl border border-solid px-5 py-[30px]">
    <h1 class="font-bold text-[20px]">{{ this.$store.state.widgetTextData.mobileForm.title }}</h1>
    <form
      :key="formOptions"
      v-validation="formOptions"
      @submit.prevent="formSubmit"
      class="form w-full flex flex-col gap-[30px] pt-[20px]">
      <div class="relative max-w-[390px] flex justify-end gap-2">
        <FormLabel
          @input="getFinInfo"
          :labelOptions="{
            id: 'telNumber',
            type: 'text',
            placeholder: '+7 (___) ___ ___',
            autocomplete: 'home tel',
            required: true,
            minlength: '18',
            maxlength: '18',
            disabledStatus: this.$store.state.formLoader,
            mask: '+7 (###) ### ## ##',
            labelText: this.$store.state.widgetTextData.mobileForm.numberField,
            style: '',
            activeClass: false,
            tabIndex: '1',
          }"
          v-model="telNumber">
        </FormLabel>
        <button
          class="form__label-button"
          :class="{
            form__label_disabled_button: codeTooltip.waitRes || this.$store.state.formError,
          }"
          id="telCodeButton"
          type="button"
          :disabled="codeTooltip.waitRes || this.$store.state.formError.length > 0"
          @click="getCode">
          {{ this.$store.state.widgetTextData.mobileForm.pinButton }}
        </button>
        <!-- <FormLabel
                :labelOptions="{
                    id: 'email',
                    type: 'email',
                    placeholder: 'sample@email.com',
                    autocomplete: 'email',
                    required: false,
                    minlength: '4',
                    maxlength: '36',
                    disabledStatus: this.$store.state.formLoader,
                    mask: false,
                    labelText: this.$store.state.widgetTextData.mobileForm.emailField,
                    style: '',
                    activeClass: false,
                    tabIndex: '2',
                }"
                v-model="email" /> -->
      </div>
      <div
        class="relative max-w-[390px] flex justify-end gap-2"
        :class="{
          loaded_hiding: !this.$store.state.formLoader,
          loaded: !this.$store.state.formLoader,
        }">
        <FormLabel
          :labelOptions="{
            id: 'sixDigitsCode',
            type: 'text',
            placeholder: 'xxxxxx',
            autocomplete: 'off',
            required: true,
            minlength: '6',
            maxlength: '6',
            disabledStatus: codeTooltip.inputDisabled || this.$store.state.formLoader,
            mask: false,
            labelText: this.$store.state.widgetTextData.mobileForm.pinField,
            style: '',
            activeClass: false,
            tabIndex: '3',
          }"
          v-model="telCode">
        </FormLabel>
        <!-- <button class="form__label-button form__submit-button" type="submit">{{ this.$store.state.widgetTextData.submitButton }}</button> -->
        <button class="form__submit-button" type="submit">
          {{ this.$store.state.widgetTextData.submitButton }}
        </button>
        <Preloader preloaderLocation="button" />
        <div
          class="tooltip__popup"
          :class="{
            'popup-open': codeTooltip.active,
            'text-[#00e600]': !codeTooltip.inputDisabled,
          }">
          <p class="tooltip__popup-text">
            {{
              codeTooltip.hintComplete
                ? this.$store.state.widgetTextData.mobileForm.pinCompleteText
                : this.$store.state.widgetTextData.mobileForm.pinErrText
            }}
          </p>
        </div>
        <!-- <button
                    class="form__label-button"
                    :class="{ form__label_disabled_button: codeTooltip.waitRes }"
                    id="telCodeButton"
                    type="button"
                    :disabled="codeTooltip.waitRes"
                    @click="getCode">
                    {{ this.$store.state.widgetTextData.mobileForm.pinButton }}
                </button> -->
      </div>
      <div class="form__submit-container">
        <OfferAgreement />
      </div>
      <p class="form__error-text">{{ this.$store.state.formError }}</p>
    </form>
  </div>
</template>
<script>
//? Импорт Компонентов
import App from '@/App.vue';
import Preloader from '@/components/Preloader/Preloader.vue';
import FormLabel from './FormLabel/FormLabel.vue';
import OfferAgreement from '@/components/OfferAgreement/OfferAgreement.vue';

export default {
  components: {
    Preloader,
    FormLabel,
    OfferAgreement,
  },

  props: {
    sendRequest: {
      type: Function,
    },
    finInfo: Function,
  },

  data() {
    return {
      codeTooltip: {
        active: true,
        waitRes: true,
        inputDisabled: true,
        hintComplete: false,
      },
      telNumber: '+7',
      email: '',
      telCode: '',
    };
  },

  computed: {
    formOptions() {
      return {
        language: this.$store.state.language,
        validationMessageList: this.$store.state.validationMessageList[this.$store.state.language],
      };
    },
  },

  methods: {
    getFinInfo() {
      if (this.telNumber.length < 18) {
        this.$store.commit('setFormError', '');
        this.codeTooltip.waitRes = true;
        return;
      } else {
        this.finInfo(
          sessionStorage.getItem('transaction-id'),
          this.telNumber.replace(/[^\d]/g, ''),
        );
        this.codeTooltip.waitRes = false;
      }
    },

    getCode() {
      this.codeTooltip.hintComplete = true;
      this.codeTooltip.active = true;
      this.codeTooltip.waitRes = true;
      this.codeTooltip.inputDisabled = false;

      setTimeout(() => {
        this.codeTooltip.active = false;
      }, 3000);

      const createData = {
        data: {
          method: 'CREATE',
          globalTagId: sessionStorage.getItem('transaction-id'),
          phone: this.telNumber.replace(/[^\d]/g, ''),
          email: this.email,
        },
      };

      this.sendRequest(createData);
    },
    formSubmit() {
      const confirmData = {
        data: {
          method: 'CONFIRM',
          phone: this.telNumber.replace(/[^\d]/g, ''),
          pin: this.telCode,
          globalTagId: sessionStorage.getItem('transaction-id'),
        },
      };

      this.sendRequest(confirmData);
    },
  },
};
</script>
<style></style>
