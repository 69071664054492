<template>
    <li class="w-full list-none">
        <RouterLink
            class="w-full flex gap-4 rounded-[10px] hover:bg-[#ECEFF4] transition-all cursor-pointer px-2 py-2 z-10"
            :to="{
                path: `/${method.type}/${method.name.replace(/ /g, '-').toLowerCase()}`,
            }"
            exact-active-class="bg-[#ECEFF4]">
            <div
                class="max-w-[50px] max-h-[50px] w-full flex justify-center items-center rounded-[10px]"
                :style="{ 'background-color': method.iconBg }"
                v-html="method.icon"></div>
            <div class="w-full">
                <p class="font-bold text-[16px] 2k:text-[18px] mb-1">{{ method.name }}</p>
                <p class="font-semibold text-[12px] 2k:text-[16px] text-[#9496A5]">{{ method.description }}</p>
            </div>
        </RouterLink>
    </li>
</template>

<script>
//? Импорт Компонентов Vue router
import { RouterLink } from 'vue-router'

export default {
    components: { RouterLink },
    props: {
        method: {
            type: Object,
            required: true,
        },
    },
}
</script>
