<template>
  <div class="max-w-[900px] w-full flex-col gap-4 sm:px-5">
    <div class="w-full flex flex-col gap-2 border-b border-solid rounded-[10px] px-5 pt-4 pb-2">
      <div class="flex flex-row">
        <p class="font-semibold">{{ this.$store.state.widgetTextData.title }}</p>
        <p class="font-bold first-letter:uppercase px-1">{{ this.transactionInfo.description }}</p>
      </div>
      <div class="flex flex-row">
        <p class="font-semibold">{{ this.$store.state.widgetTextData.paymentAmount }}</p>
        <p class="font-bold px-1">
          {{ this.transactionInfo.amount }}<span class="text-sm ml-1">₸</span>
        </p>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <p class="font-semibold">{{ this.$store.state.widgetTextData.feeAmount }}</p>
          <p class="font-bold px-1">
            {{ this.transactionInfo.fee_amount ? this.transactionInfo.fee_amount : 0 }}
            <span class="text-sm ml-1">₸</span>
          </p>
        </div>
        <p class="font-semibold text-[12px] opacity-[0.8]">
          {{ this.$store.state.widgetTextData.feeDescription }}
        </p>
      </div>

      <div class="flex flex-row">
        <p class="font-semibold">{{ this.$store.state.widgetTextData.totalAmount }}</p>
        <p class="font-bold px-1">
          {{
            this.transactionInfo.total_amount
              ? this.transactionInfo.total_amount
              : this.transactionInfo.amount
          }}<span class="text-sm ml-1">₸</span>
        </p>
      </div>
    </div>
    <div
      class="w-full flex flex-col-reverse items-center md:flex-row md:items-start gap-[50px] px-2 sm:px-5 py-8">
      <SideBar :transactionMethods="this.transactionMethods" />
      <RouterView />
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar/SideBar.vue';

export default {
  components: { SideBar },
  props: {
    transactionMethods: {
      type: Array,
      required: true,
    },
    transactionInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>
