<template>
    <div class="max-w-[450px] w-full bg-white rounded-[10px] shadow-2xl px-5 py-[30px]">
        <h1 class="font-bold text-[20px]">{{ this.$store.state.widgetTextData.cardForm.title }}</h1>
        <form :key="formOptions" class="form w-full flex flex-col gap-[30px] pt-[20px]" v-validation="formOptions">
            <FormLabel
                :labelOptions="{
                    id: 'cardNumber',
                    type: 'text',
                    placeholder: '0000 0000 0000 0000',
                    autocomplete: 'cc-number',
                    required: true,
                    minlength: '19',
                    maxlength: '19',
                    disabledStatus: formLoader,
                    mask: '#### #### #### ####',
                    labelText: this.$store.state.widgetTextData.cardForm.numberField,
                    style: '',
                    activeClass: true,
                    tabIndex: '1',
                }"
                v-model="cardNumber">
                <div class="form__label-icon" v-html="cardOperator"></div>
            </FormLabel>
            <div class="max-w-[390px] flex gap-2">
                <FormLabel
                    :labelOptions="{
                        id: 'cardDate',
                        type: 'text',
                        placeholder: '00/00',
                        autocomplete: 'cc-exp',
                        required: true,
                        minlength: '5',
                        maxlength: '5',
                        disabledStatus: formLoader,
                        mask: '##/##',
                        labelText: this.$store.state.widgetTextData.cardForm.dateField,
                        style: '',
                        activeClass: true,
                        tabIndex: '2',
                    }"
                    v-model="cardDate" />
                <FormLabel
                    :labelOptions="{
                        id: 'cardCvv',
                        type: 'password',
                        placeholder: '***',
                        autocomplete: 'cc-csc',
                        required: true,
                        minlength: '3',
                        maxlength: '3',
                        disabledStatus: formLoader,
                        mask: '###',
                        labelText: this.$store.state.widgetTextData.cardForm.pinField,
                        style: '',
                        activeClass: true,
                        tabIndex: '3',
                    }"
                    v-model="cardCvv" />
            </div>
            <FormLabel
                :labelOptions="{
                    id: 'cardName',
                    type: 'text',
                    placeholder: '',
                    autocomplete: 'cc-name',
                    required: true,
                    minlength: '2',
                    maxlength: '26',
                    disabledStatus: formLoader,
                    mask: false,
                    labelText: this.$store.state.widgetTextData.cardForm.nameField,
                    style: {
                        textTransform: 'uppercase',
                    },
                    activeClass: true,
                    tabIndex: '4',
                }"
                v-model="cardName" />
            <FormLabel
                :labelOptions="{
                    id: 'email',
                    type: 'email',
                    placeholder: 'sample@email.com',
                    autocomplete: 'on',
                    required: true,
                    minlength: '4',
                    maxlength: '36',
                    disabledStatus: formLoader,
                    mask: false,
                    labelText: this.$store.state.widgetTextData.cardForm.emailField,
                    style: '',
                    activeClass: false,
                    tabIndex: '5',
                }"
                v-model="email" />
            <div class="form__submit-container">
                <OfferAgreement />
                <button class="form__submit-button" type="submit">{{ this.$store.state.widgetTextData.submitButton }}</button>
                <!-- <Preloader preloaderLocation="button" /> -->
            </div>
            <p class="form__error-text"></p>
        </form>
    </div>
</template>
<script>
//? Импорт иконок операторов
import cardIconsData from '@/data/cardIcons/cardIcons.json'
//? Импорт компонентов
import FormLabel from './FormLabel/FormLabel.vue'
import Preloader from '@/components/Preloader/Preloader.vue'
import OfferAgreement from '@/components/OfferAgreement/OfferAgreement.vue'

export default {
    components: {
        Preloader,
        FormLabel,
        OfferAgreement,
    },

    props: {},

    data() {
        return {
            cardIcons: cardIconsData.data,
            cardOperator: null,
            cardDate: '',
            cardCvv: '',
            cardNumber: '',
            cardName: '',
            email: '',
            formLoader: false,
        }
    },

    computed: {
        formOptions() {
            return {
                language: this.$store.state.language,
                validationMessageList: this.$store.state.validationMessageList[this.$store.state.language],
            }
        },
    },

    methods: {
        getCardOperator() {
            let operatorIcon = null
            this.cardIcons.forEach((item) => {
                if (this.cardNumber.slice(0, 1) === item.first_number && this.cardNumber.length > 0) {
                    operatorIcon = item.icon
                }
            })
            this.cardOperator = operatorIcon
        },
    },

    watch: {
        cardNumber() {
            this.getCardOperator()
        },
    },
}
</script>
<style></style>
