<template>
    <div class="w-full h-full flex flex-col items-center justify-center text-center gap-8 p-[100px] min-h-[500px]">
<!--        <svg width="250px" height="200px" viewBox="0 0 271 191" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path-->
<!--                d="M270.583 101.964V141.115C270.552 153.191 265.827 164.764 257.44 173.303C249.053 181.842 237.687 186.653 225.827 186.685H114.747C102.884 186.662 91.5131 181.854 83.1245 173.313C74.7359 164.772 70.0133 153.194 69.991 141.115V96.7327C70.0133 84.6538 74.7359 73.0761 83.1245 64.5351C91.5131 55.994 102.884 51.1856 114.747 51.1628H217.021V81.2272H114.747C110.722 81.2587 106.871 82.9006 104.025 85.7984C101.179 88.6962 99.5665 92.6175 99.5356 96.7155V141.115C99.5665 145.213 101.179 149.134 104.025 152.032C106.871 154.93 110.722 156.572 114.747 156.603H225.827C229.852 156.572 233.703 154.93 236.549 152.032C239.395 149.134 241.008 145.213 241.039 141.115V129.069H123.232V102.154C127.796 102.154 132.303 102.09 136.754 101.964H270.583Z"-->
<!--                fill="#6FCF97" />-->
<!--            <path-->
<!--                d="M23.0881 190.931V45.501C23.1283 33.434 27.8588 21.874 36.2454 13.3478C44.6321 4.82161 55.9929 0.0226728 67.8445 0L225.827 0C237.688 0.0317973 249.053 4.8431 257.44 13.3822C265.827 21.9213 270.552 33.4937 270.583 45.5698V81.3132H241.056V45.5698C241.025 41.4718 239.412 37.5506 236.566 34.6528C233.72 31.755 229.869 30.1131 225.844 30.0816H67.8445C63.8197 30.1131 59.9684 31.755 57.1224 34.6528C54.2763 37.5506 52.6637 41.4718 52.6327 45.5698V191L23.0881 190.931Z"-->
<!--                fill="#59499A" />-->
<!--            <path-->
<!--                d="M11.0877 16.6754V4.45689H13.5216L16.3611 13.0615C16.6315 13.8703 16.8174 14.4726 16.9358 14.7824C17.071 14.3349 17.2907 13.681 17.578 12.8205L20.4514 4.31921H22.581V16.5377H21.0598V6.45315L17.5273 16.6754H16.0907L12.6089 6.34989V16.6754H11.0877ZM3.95506 16.6754V5.90246H0V4.45689H9.5158V5.90246H5.54384V16.6754H3.95506Z"-->
<!--                fill="#312783" />-->
<!--        </svg>-->
        <h3 class="max-w-[350px] font-bold text-[20px] md:text-[26px] 2k:text-[32px]">{{ message }}</h3>
        <a :href="redirectLink"  class="font-bold text-[16px] rounded-[10px] border border-solid border-gray-500 p-4"
            >{{ this.$store.state.widgetTextData.closePageButton }}</a
        >
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
        },
        redirectLink: String,
    },
    methods: {
      
    },
}
</script>
<style></style>
