import Vue, { createApp } from 'vue'
import App from './App'
//? Импорт Роутера
import router from '@/router/router'

//? Импорт директив приложения
import { focus, validation } from '@/directives/directives'
import store from './store'
import './main.css'

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
app.directive('focus', focus)
app.directive('validation', validation)
