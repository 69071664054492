import { createStore } from 'vuex';

export default createStore({
  state: {
    formLoader: false,
    formError: '',
    language: 'ru',
    widgetTextData: {},
    validationMessageList: {
      ru: {
        missingField: 'Поле пропущено',
        incorrectData: 'Введены некорректные данные',
        incorrectEmail: 'Введён некорректный E-mail',
        cardNumberLength: 'Номер карты должен состоять из 16 цифр, сейчас введено: ',
        cardWrongNumber: 'Неправильный номер карты',
        cardExpirationDate: 'Истёк срок годности карты',
        telWrongOperator: 'Неверный номер телефона',
        telNumberLength: 'Минимальное количество символов: 12 , сейчас: ',
      },
      en: {
        missingField: 'The field is skipped',
        incorrectData: 'Incorrect data entered',
        incorrectEmail: 'Incorrect E-mail was entered',
        cardNumberLength: 'The card number must consist of 16 digits, now entered: ',
        cardWrongNumber: 'Incorrect card number',
        cardExpirationDate: "The card's expiration date has expired",
        telWrongOperator: 'Invalid phone number',
        telNumberLength: 'Minimum number of characters: 12 , now: ',
      },
    },
  },

  getters: {
    getLanguage: (state) => {
      return state.language;
    }
  },

  mutations: {
    setFormLoader(state) {
      state.formLoader = !state.formLoader;
    },
    setFormError(state, message) {
      state.formError = message;
    },

    setWidgetTextData(state, data) {
      state.widgetTextData = data;
    },
    setLanguage(state, lang) {
      state.language = lang;
    },
  },

  actions: {},
  modules: {},
});
