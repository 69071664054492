<template>
    <div class="preloader">
        <svg
            :class="[preloaderLocation === 'home' ? 'preloader__image-home' : 'preloader__image']"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path
                fill="currentColor"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        preloaderLocation: {
            type: String,
            required: true,
        },
    },
}
</script>
<style scoped>
.preloader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #e0e0e0;
    border-radius: 10px;
    z-index: 1001;
}

.preloader__image {
    width: 20px;
    height: 20px;
    text-align: center;
    animation: preloader-rotate 2s infinite linear;
}

.preloader__image-home {
    width: 50px;
    height: 50px;
    text-align: center;
    animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
    100% {
        transform: rotate(360deg);
    }
}

.loaded_hiding .preloader {
    transition: 0.3s opacity;
    opacity: 0;
}

.loaded .preloader {
    display: none;
}
</style>
