<template>
  <div
    class="w-full md:h-[100vh] flex justify-center items-start bg-[#f5f6fa] bg-center bg-cover text-[#212B31] pt-10">
    <div class="max-w-[900px] w-full flex flex-col bg-white shadow-2xl rounded-[10px]">
      <Header :languageSettingHandler="languageSettingHandler" :currentLanguage="currentLanguage" />
      <PaymentPage
        :transactionMethods="transactionMethods"
        :transactionInfo="transactionInfo"
        v-if="!serviceError.isError && !transactionResult.isSuccess" />
      <ServiceErrorPage
        v-if="serviceError.isError"
        :message="serviceError.message"
        :redirectLink="serviceError.link" />
      <TransactionResult
        v-if="transactionResult.isSuccess"
        :message="transactionResult.message"
        :redirectLink="transactionResult.link" />
      <div
        class="w-full h-full"
        :class="{ loaded_hiding: !preloaderVissible, loaded: !preloaderVissible }">
        <Preloader preloaderLocation="home" />
      </div>
    </div>
  </div>
</template>

<script>
//? Импорт страниц
import PaymentPage from '@/pages/PaymentPage.vue';
import ServiceErrorPage from '@/pages/ServiceErrorPage.vue';
import TransactionResult from '@/pages/TransactionResult.vue';
//? Импорт данных методов оплаты
import methodsData from '@/data/methods/methods.json';
//? Импорт текстового контента
import widgetTextData from '@/data/text/text.json';

//? Импорт функций подключения к API  транзакций
import {
  getTransactionInfo,
  createDcbTransaction,
  confirmDcbTransaction,
  getFinInfo,
} from '@/utills/api';

//? Импорт компонентов формы
import CardForm from '@/components/PaymentForms/CardForm';
import DcbForm from '@/components/PaymentForms/DcbForm';
import EwalletForm from '@/components/PaymentForms/EwalletForm';

//? Импорт инных компонентов
import Preloader from './components/Preloader/Preloader.vue';
import Header from '@/components/Header/Header.vue';

export default {
  components: { PaymentPage, Preloader, ServiceErrorPage, TransactionResult, Header },

  created() {
    this.transactionInitHandler();
  },

  // mounted() {
  //   this.languageInitHandler();
  // },
  // watch: {
  //   currentLanguage() {
  //     this.languageInitHandler();
  //   },
  // },
  data: function () {
    return {
      transactionMethods: [],
      transactionInfo: {
        amount: '',
        description: '',
        fee_amount: '',
        total_amount: '',
      },

      preloaderVissible: false,
      serviceError: {
        isError: false,
        message: '',
        link: '',
      },
      transactionResult: {
        isSuccess: false,
        message: '',
        link: '',
      },
      currentLanguage: '',
    };
  },

  methods: {
    // languageInitHandler() {
    //   const allowedLanguage = Object.keys(widgetTextData).includes(this.$store.state.language);
    //   // //?  Проверка наличия языка
    //   if (!allowedLanguage) {
    //     this.$store.commit('setLanguage', 'ru');
    //   }
    //   //? Проверка языка в локальном хранилище
    //   if (sessionStorage.getItem('language') && sessionStorage.getItem('language') !== 'null') {
    //     this.$store.commit('setLanguage', sessionStorage.language.toLowerCase());
    //   }
    //   //? Установка языка при отсутствии в локальном хранилище
    //   if (this.currentLanguage === '') {
    //     this.currentLanguage = this.$store.state.language;
    //   } else {
    //     this.$store.commit('setLanguage', this.currentLanguage);
    //   }
    //   this.currentLanguage = lang;
    //   this.$store.commit('setLanguage', this.currentLanguage);
    //   //? Выбор языковых данных для виджета отталкиваясь от языка
    //   this.$store.commit('setWidgetTextData', widgetTextData[this.currentLanguage]);
    // },

    languageSettingHandler(lang) {
      this.currentLanguage = lang;
      // this.$store.commit('setLanguage', lang);
      this.$store.commit('setWidgetTextData', widgetTextData[lang]);
    },

    //? Иниациализация виджета
    transactionInitHandler() {
      this.preloaderVissible = true;

      setTimeout(() => {
        if (this.$route.params.id) {
          sessionStorage.setItem('transaction-id', this.$route.params.id);
        }

        getTransactionInfo(sessionStorage.getItem('transaction-id'))
          .then((res) => {
            if (res.success) {
              this.preloaderVissible = false;
              this.transactionInfo.amount = res.amount;
              this.transactionInfo.description = res.description;
              res.allowed_methods.forEach((method) => {
                methodsData.data.map((item) => {
                  if (item.type === method) {
                    this.transactionMethods.push(item);
                    this.createNewRoute(item);
                  }
                });
              });

              if (res.lang) {
                let lang = res.lang.toLowerCase();
                let langs = ['ru', 'en', 'kz'];

                if (langs.includes(lang)) {
                  this.languageSettingHandler(lang);
                } else {
                  this.languageSettingHandler('en');
                }
              } else {
                this.languageSettingHandler('ru');
              }

              this.$router.push({
                path: `/${res.payment_method.type}/${res.payment_method.name
                  .replace(/ /g, '-')
                  .toLowerCase()}`,
              });
            } else if (res.error === 'transaction_completed') {
              this.preloaderVissible = false;
              this.serviceError.message = this.$store.state.widgetTextData.transactionRepeat;
              this.serviceError.isError = true;
              this.serviceError.link = res.redirect;
              this.languageSettingHandler('ru');
            } else {
              this.preloaderVissible = false;
              this.serviceError.isError = true;
              this.languageSettingHandler('ru');
            }
          })
          .catch((err) => {
            console.log(err);
            this.preloaderVissible = false;
            this.serviceError.isError = true;
          });
      });
    },

    //? Создание роутов методов оплаты
    createNewRoute(item) {
      function initComponent(type) {
        if (type === 'card') {
          return CardForm;
        } else if (type === 'dcb') {
          return DcbForm;
        } else if (type === 'e-wallet') {
          return EwalletForm;
        }
      }

      const routeComponent = initComponent(item.type);

      this.$router.addRoute({
        path: `/${item.type}/${item.name.replace(/ /g, '-').toLowerCase()}`,
        component: routeComponent,
        props: {
          sendRequest: this.sendRequest,
          finInfo: this.finInfo,
        },
      });
    },

    //? Отправка данных формы и данных на получения PIN-Кода
    sendRequest(formData) {
      if (formData.data.method === 'CREATE') {
        this.$store.commit('setFormError', '');
        createDcbTransaction(formData)
          .then((res) => {
            if (res.status === 500 || res.code === 2000) {
              if (res.message) {
                this.serviceError.message = res.message;
                this.serviceError.link = res.redirect;
              }
              this.serviceError.isError = true;
            } else if (res.isError) {
              this.$store.commit('setFormError', res.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.serviceError.isError = true;
          });
      } else if (formData.data.method === 'CONFIRM') {
        this.$store.commit('setFormLoader');
        this.$store.commit('setFormError', '');
        confirmDcbTransaction(formData)
          .then((res) => {
            this.$store.commit('setFormLoader');

            if (res.status === 500) {
              this.serviceError.message = res.message;
              this.serviceError.isError = true;
              this.serviceError.link = res.redirect;
            } else if (res.code === 2000) {
              this.serviceError.message = res.message;
              this.serviceError.isError = true;
              this.serviceError.link = res.redirect;
            } else if (res.code === 1012) {
              this.serviceError.message = this.$store.state.widgetTextData.techPages.notBalance;
              this.serviceError.isError = true;
              this.serviceError.link = res.redirect;
            } else if (!res.isError) {
              console.log('debug' + res.isError);
              const message = this.$store.state.widgetTextData.techPages.transactionComplete;
              this.transactionResHandler(true, message, res.redirect);
            } else if (res.code === 1001){
              this.serviceError.message = res.message;
              this.serviceError.isError = true;
              this.serviceError.link = res.redirect;
            } else if (res.code === 1000){
              this.serviceError.message = this.$store.state.widgetTextData.techPages.serviceError;
              this.serviceError.isError = true;
              this.serviceError.link = res.redirect;
              // location.replace(res.redirect);
            } else if (res.code === 1017) {
              this.serviceError.message = this.$store.state.widgetTextData.techPages.methodNotAllowed;
              this.serviceError.isError = true;
              this.serviceError.link = res.redirect;
            } else {
              this.$store.commit('setFormError', res.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.serviceError.isError = true;
          });
      }
    },

    finInfo(id, phone) {
      getFinInfo(id, phone)
        .then((res) => {
          if (res.success) {
            this.transactionInfo.fee_amount = res.fee_amount;
            this.transactionInfo.total_amount = res.total_amount;
          } else if (res.error === 'unsupported_operator') {
            this.$store.commit(
              'setFormError',
              this.$store.state.widgetTextData.techPages.operatorNotAvailable,
            );
          } else {
            this.$store.commit(
              'setFormError',
              this.$store.state.widgetTextData.techPages.noneOperator,
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.serviceError.isError = true;
        });
    },

    transactionResHandler(status, message, link) {
      this.transactionResult.isSuccess = status;
      // console.log(this.transactionResult.isSuccess);
      this.transactionResult.message = message;
      // location.replace(link);
      this.transactionResult.link = link;
    },
  },
};
</script>
