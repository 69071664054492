const BASE_URL = 'https://api.paygate.kz';
const checkResponse = (response) =>
  response.ok ? response.json() : Promise.reject(`Ошибка ${response.status}`);

export function getTransactionInfo(global_tag_id) {
  return fetch(`${BASE_URL}/api/get-transaction-info`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({ global_tag_id }),
  }).then(checkResponse);
}

export function createDcbTransaction(data) {
  return fetch(`${BASE_URL}/api/dcb-create`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(data),
  }).then(checkResponse);
}

export function getFinInfo(global_tag_id, phone) {
  return fetch(`${BASE_URL}/api/dcb-fininfo`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({ global_tag_id, phone }),
  }).then(checkResponse);
}

export function confirmDcbTransaction(data) {
  return fetch(`${BASE_URL}/api/dcb-confirm`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(data),
  }).then(checkResponse);
}
