<template>
    <div class="max-w-[450px] w-full pb-4 md:max-w-[300px] 2k:max-w-[600px]">
        <p class="font-semibold text-base text-[#9496A5] 2k:text-[22px] mb-4">{{ this.$store.state.widgetTextData.sideBarTitle }}</p>
        <nav class="flex flex-col gap-2.5">
            <SideBarItem v-for="method in transactionMethods" :method="method" />
        </nav>
    </div>
</template>

<script>
import SideBarItem from './SideBarItem.vue'

export default {
    components: { SideBarItem },
    props: {
        transactionMethods: {
            type: Array,
            required: true,
        },
    },
}
</script>
