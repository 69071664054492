<template>
  <label
    :for="labelOptions.autocomplete"
    class="form__label"
    :class="{ filled: labelOptions.activeClass }"
    v-focus
    :tabindex="labelOptions.tabIndex">
    <input
      v-if="labelOptions.mask"
      :tabindex="labelOptions.tabIndex"
      class="form__label-input"
      :style="labelOptions.style"
      :id="labelOptions.id"
      :type="labelOptions.type"
      :placeholder="labelOptions.placeholder"
      :autocomplete="labelOptions.autocomplete"
      :name="labelOptions.autocomplete"
      :required="labelOptions.required"
      :minlength="labelOptions.minlength"
      :maxlength="labelOptions.maxlength"
      :disabled="labelOptions.disabledStatus"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-mask="labelOptions.mask" />
    <input
      v-else
      class="form__label-input filled"
      :tabindex="labelOptions.tabIndex"
      :style="labelOptions.style"
      :id="labelOptions.id"
      :type="labelOptions.type"
      :placeholder="labelOptions.placeholder"
      :autocomplete="labelOptions.autocomplete"
      :name="labelOptions.autocomplete"
      :required="labelOptions.required"
      :minlength="labelOptions.minlength"
      :maxlength="labelOptions.maxlength"
      :disabled="labelOptions.disabledStatus"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)" />
    <span>{{ labelOptions.labelText }} </span>
    <slot></slot>
    <p class="form__label-text"></p>
  </label>
</template>

<script>
import { mask } from 'vue-the-mask';
export default {
  props: {
    labelOptions: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  directives: {
    mask,
  },
};
</script>

<style>
.form__label {
  position: relative;
  max-width: 390px;
  max-height: 56px;
  border: 1px solid #d8e7ed;
  cursor: pointer;
  text-align: center;
  flex-grow: 1;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  position: relative;
  height: 56px;
  background: #f1f5f9;
  transition: 0.3s;
  outline: none;
  padding: 12px 12px 0 12px;
}

.form__label-input {
  width: 100%;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  opacity: 0;
  visibility: hidden;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: transparent;
  padding-top: 10px;
  margin: 0;
}

.form__label-button {
  width: 50%;
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  background-color: #197af0 !important;
  border-radius: 10px;
  padding: 12px 0;
  transition: 0.3s;
}

@media screen and (min-width: 640px) {
  .form__label-button {
    width: 30%;
    font-size: 12px;
  }
}

.form__label-icon {
  width: 36px;
  position: absolute;
  right: 12px;
  top: 10px;
  transition: 0.3s;
}

.form__label-button svg {
  stroke: black;
  transition: 0.3s;
}

.form__label-button svg:hover {
  stroke: #3489f0;
}

.form__label_disabled_button {
  background-color: gray !important;
    cursor: no-drop !important;
}

.active {
  border-color: #197af0;
  background: transparent;
}

.form__label span {
  position: absolute;
  left: 12px;
  top: 20px;
  color: #90a4ae;
  transition: 0.3s;
  font: 400 12px/12px 'Roboto', sans-serif;
}

.form__label.filled span {
  transform: translateY(-10px);
  font: 400 10px/10px 'Roboto', sans-serif;
}

@media screen and (min-width: 640px) {
  .form__label span {
    font: 400 16px/16px 'Roboto', sans-serif;
  }
  .form__label.filled span {
    font: 400 14px/14px 'Roboto', sans-serif;
  }
}

@media screen and (min-width: 2000px) {
  .form__label span {
    font: 400 18px/18px 'Roboto', sans-serif;
  }
  .form__label.filled span {
    font: 400 16px/16px 'Roboto', sans-serif;
  }
}

.form__label.filled .form__label-input {
  visibility: visible;
  opacity: 1;
}

.form__submit-container {
  position: relative;
  margin-top: 20px;
}

.form__submit-button {
  width: 50%;
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  background-color: #197af0 !important;
  border-radius: 10px;
  padding: 12px 0;
  transition: 0.3s;
}

@media screen and (min-width: 640px) {
  .form__submit-button {
    width: 30%;
    font-size: 12px;
  }
}

.form__submit_disabled_button {
  background-color: gray !important;
    cursor: no-drop !important;
}

.form__error-text {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  color: #ea0c00;
}
.tooltip__popup {
  width: 100%;
  position: absolute;
  bottom: -20px;
  right: 0;
  text-align: center;
  visibility: hidden;
  color: #ea0c00;
  opacity: 0;
  transition: 0.3s;
}

.tooltip__popup-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  font-weight: bold;
}

@media screen and (min-width: 2000px) {
  .tooltip__popup-text {
    font-size: 12px;
  }
}

.popup-open {
  visibility: visible;
  opacity: 1;
}

.label-error {
  border-color: #ea0c00;
}
.allowe-code {
  border-color: #85eb2c;
}

.forbidden-code {
  border-color: #ea0c00;
}

.form__label-text {
  width: 100%;
  min-height: 30px;
  font-size: 10px;
  font-weight: 600;
  color: #ea0c00;
  position: absolute;
  bottom: -30px;
  left: 0px;
}
</style>
